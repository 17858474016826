import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CartContext from '../../Context/CartContext'
import LoadingIndicator from '../../loadingindicator'

import { getOrder } from '../../../lib/api/order'

class OrderConfirmation extends Component {
  context = null

  constructor(props) {
    super(props)

    const { id } = this.props

    this.state = {
      order: null,
      id: id,
      loading: (id ? true : false),
    }
  }

  componentDidMount() {
    const { id } = this.state

    if (id) {
      getOrder(id).then(result => {
        if (result && result.order) {
          this.setState({ order: result.order, loading: false })
        }
      })
    }
  }

  render() {
    const { order, loading, id } = this.state

    let content = ''

    if (order) {
      if (order.status === 'P') {
        content = (
          <>
            <h1 className="h2">Bedankt voor jouw bestelling!</h1>
            <h2 className="h3">Je ontvangt zo dadelijk nog een orderbevestiging via e-mail.</h2>
          </>
        )
      } else {
        content = (
          <>
            <h1 className="h2">Bedankt voor uw bestelling!</h1>
            <h2 className="h3">Je ontvangt zo dadelijk nog een orderbevestiging via e-mail.</h2>
          </>
        )
      }
    }

    if (!id) {
      content = (
        <>
          <div className="alert alert-danger">
            <p>missing id</p>
          </div>
        </>
      )
    }

    return (
      <CartContext.Consumer>
        {context => {
          this.context = context

          return (
            <LoadingIndicator loading={loading}>
              {content}
            </LoadingIndicator>
          )
        }}
      </CartContext.Consumer>
    )
  }
}

OrderConfirmation.propTypes = {
  id: PropTypes.string,
}

export default OrderConfirmation
