import React, { Component } from 'react'
import queryString from 'query-string'

import Layout from '../../components/layout'
import OrderConfirmation from '../../components/Order/Confirmation'

export default class OrderConfirmationPage extends Component {
  constructor(props) {
    super(props)

    const params = queryString.parse(this.props.location.search)
    const id = (params && params.id) ? params.id : null

    this.state = {
      id: id,
    }
  }

  render() {
    const { id } = this.state

    return (
      <Layout>
        <OrderConfirmation id={id} />
      </Layout>
    )
  }
}
